import { NodeFactory } from '@/classes/Documents/NodeFactory.class'
import { Node } from '@/classes/Documents/Node.class'
import { Document } from '@/classes/Documents/Document.class'

import { CompareFunction } from '@/helpers/methods'

export class Folder extends Node {
	ghostNodes = []
	is_search_results = false
	opened = false

	constructor (rawNode) {
		super(rawNode)
		if (this.is_trash) {
			this.alias = 'trash'
		}
		if (!this.hasOwnProperty('has_sub_folders')) {
			this.has_sub_folders = false
		}
		if (!this.has_sub_folders || this.is_empty) {
			this._folders = null
		}
		if (this.is_empty || this.is_root) {
			this._documents = null
		}
	}

	get documents () {
		let result = this._documents
		if (!this.is_empty && !this._documents) {
			result = []
		}
		return result
	}
	set documents (documents) {
		if (Array.isArray(documents)) {
			if (!this._documents) {
				this._documents = []
			}
			documents.forEach(newDocument => {
				const existingDocument = this._documents.find(document => document.id == newDocument.id)
				if (existingDocument) {
					existingDocument.update(newDocument)
				} else {
					const document = new Document(newDocument)
					document.structure = this.structure
					this._documents.push(document)
				}
			})
			this._documents.forEach((existingDocument, existingDocumentIndex) => {
				const newDocument = documents.find(document => document.id == existingDocument.id)
				if (!newDocument) {
					this._documents.splice(existingDocumentIndex, 1)
				}
			})
		}
	}

	get folders () {
		let result = undefined
		if (this.has_sub_folders && !this.is_empty || this._folders && this._folders.length > 0) {
			if (!this._folders) {
				result = []
			} else {
				result = this._folders
			}
		}
		return result
	}
	set folders (folders) {
		if (Array.isArray(folders)) {
			if (!this._folders) {
				this._folders = []
			}
			folders.forEach(newFolder => {
				const existingFolder = this._folders.find(folder => (folder.id == newFolder.id || folder.name == newFolder.name))
				if (existingFolder) {
					existingFolder.update(newFolder)
				} else {
					newFolder.structure = this.structure
					const folder = new Folder(newFolder)
					this._folders.push(folder)
				}
			})
			this._folders.forEach((existingFolder, existingFolderIndex) => {
				const newFolder = folders.find(folder => folder.id == existingFolder.id)
				if (!newFolder) {
					this._folders.splice(existingFolderIndex, 1)
				}
			})
		}
	}

	get has_child () {
		return this._folders?.length > 0 || this._documents?.length > 0
	}

	get is_trash () {
		return this.id == -1
	}

	get children () {
		let result = []
		if (this._folders) {
			result.push(...this._folders)
		}
		if (this._documents) {
			result.push(...this._documents)
		}
		if (result.length == 0) {
			result = null
		}
		return result
	}

	get openedFolders () {
		let result = []
		if (this.opened) {
			const folderIndex = result.findIndex(entry => entry.id == this.id)
			if (folderIndex == -1) {
				result.push(this)
			}
		}
		this._folders
			?.map(folder => folder.openedFolders)
			.flat()
			.forEach(folder => {
				const folderIndex = result.findIndex(entry => entry.id == folder.id)
				if (folderIndex == -1) {
					result.push(folder)
				}
			})
		return result
	}

	markAsOpened () {
		this.opened = true
	}
	markAsClosed () {
		this.opened = false
	}
	findParentNode (nodeIdentifier) {
		let result = null
		if (this.children?.some(child => [child.id, child.hash, child.alias].includes(nodeIdentifier))) {
			result = this
		}
		if (!result && this._folders) {
			for (const folder of this._folders) {
				result = folder.findParentNode(nodeIdentifier)
				if (result) {
					break
				}
			}
		}
		return result
	}
	findNode (nodeIdentifier) {
		let result = null
		if (nodeIdentifier && (this.id == nodeIdentifier || this.hash == nodeIdentifier || this.alias == nodeIdentifier)) {
			result = this
		}
		if (nodeIdentifier && !result) {
			result = this._documents?.find(document => document.id == nodeIdentifier || document.hash == nodeIdentifier)
		}
		if (nodeIdentifier && !result && this._folders) {
			for (const folder of this._folders) {
				result = folder.findNode(nodeIdentifier)
				if (result) {
					break
				}
			}
		}
		if (!result) {
			result = this.findGhostNode(nodeIdentifier)
		}
		return result
	}
	findGhostNode (nodeIdentifier) {
		let result = null
		if (nodeIdentifier && this.structure) {
			result = this.structure.ghostNodes.find(
				ghostNode => ghostNode.id == nodeIdentifier || ghostNode.hash == nodeIdentifier || ghostNode.alias == nodeIdentifier
			)
		}
		return result
	}
	add (rawNode) {
		let result = null
		const rawNodeVendorId = rawNode.vendor ? rawNode.vendor.id : rawNode.vendor_id
		if (rawNode && (!this.vendor?.id || rawNodeVendorId == this.vendor.id)) {
			if (Node.isFolderNode(rawNode)) {
				result = this.addFolder(rawNode)
			} else if (Node.isDocumentNode(rawNode)) {
				result = this.addDocument(rawNode)
			}
		}
		this.structure?.tryToAttachGhostNodes()
		return result
	}
	addDocument (rawNode) {
		let result = null
		const rawNodeVendorId = rawNode.vendor ? rawNode.vendor.id : rawNode.vendor_id
		if (rawNode && (!this.vendor?.id || rawNodeVendorId == this.vendor.id) && Node.isDocumentNode(rawNode)) {
			if (!this.structure && this.is_root) {
				this.structure = this
			}
			rawNode.structure = this.structure
			rawNode.folder_id = this.id
			if (!this._documents) {
				this._documents = []
			}
			if (!this._documents.map(document => document.id).includes(rawNode.id)) {
				this._documents.push(rawNode instanceof Document ? rawNode : new Document(rawNode))
			}
		}
		return result
	}
	 // eslint-disable-next-line
	addFolder (rawNode) {
		let result = null
		const rawNodeVendorId = rawNode.vendor ? rawNode.vendor.id : rawNode.vendor_id
		if (rawNode && (!this.vendor?.id || rawNodeVendorId == this.vendor.id) && Node.isFolderNode(rawNode)) {
			if (!this.structure && this.is_root) {
				this.structure = this
			}
			rawNode.structure = this.structure
			rawNode.folder_id = this.id
			if (!this._folders) {
				this._folders = []
			}
			if (!this._folders.map(folder => folder.id).includes(rawNode.id) && !this._folders.map(folder => folder.name).includes(rawNode.name)) {
				this._folders.push(rawNode instanceof Folder ? rawNode : new Folder(rawNode))
			}
			this.has_sub_folders = true
		}
		return result
	}
	update (rawNode) {
		const rawNodeVendorId = rawNode.vendor ? rawNode.vendor.id : rawNode.vendor_id
		if (rawNode && (!this.vendor?.id || rawNodeVendorId == this.vendor.id)) {
			super.update(rawNode)
		}
		this.structure?.tryToAttachGhostNodes()
		return this
	}
	remove (rawNode) {
		let result = false
		const node = rawNode instanceof Node ? rawNode : NodeFactory.createNode(rawNode)
		if (node.is_folder) {
			result = this.removeFolder(node)
		} else if (node.is_document) {
			result = this.removeDocument(node)
		}
		this.has_sub_folders = this._folders && this._folders.length > 0
		return result
	}
	removeFolder (node) {
		let result = false
		if (this._folders) {
			const nodeIndex = this._folders.findIndex(subfolder => subfolder.id == node.id)
			if (nodeIndex != -1) {
				this._folders.splice(nodeIndex, 1)
				result = true
			} else {
				for (const subfolder of this._folders) {
					result = subfolder.remove(node)
					if (result) {
						break
					}
				}
			}
		}
		if (result) {
			this.updateCounter()
		}
		return result
	}
	removeDocument (node) {
		let result = false
		if (this._documents) {
			const nodeIndex = this._documents.findIndex(document => document.id == node.id)
			if (nodeIndex != -1) {
				this._documents.splice(nodeIndex, 1)
				result = true
			}
		}
		if (result) {
			this.updateCounter()
		}
		return result
	}
	resetSort () {
		this.deepSort({ field: 'name', order: 'asc' })
	}
	deepSort (sort) {
		if (this._folders) {
			this._folders.sort(CompareFunction(sort.field, sort.order))
			this._folders.forEach(folder => folder.deepSort(sort))

		}
	}
	clearChildren () {
		if (this.children) {
			this.children.forEach(child => this.remove(child))
		}
	}
	updateCounter () {
		this.counter = this.children?.map(child => child.counter).reduce((sumResult, counter) => sumResult + counter, 0)
	}
	removeCounter () {
		this.children?.forEach(child => child.removeCounter())
		this.counter = 0
	}
	toString (indentation = '') {
		let result = `${indentation}${this.name}`
		this._folders?.forEach(subfolder => {
			result += '\n'
			result += subfolder.toString(indentation + '\t')
		})
		this._documents?.forEach(subdocument => {
			result += '\n'
			result += subdocument.toString(indentation + '\t')
		})
		return result
	}
}
