/* eslint-disable vue/component-definition-name-casing */

import Vue from 'vue'

// Alerts
Vue.component('w-alert', () => ({
	component: import('@/components/Commons/Alerts/WAlert')
}))

Vue.component('w-error', () => ({
	component: import('@/components/Commons/Alerts/WError')
}))

Vue.component('w-warning', () => ({
	component: import('@/components/Commons/Alerts/WWarning')
}))

Vue.component('w-info', () => ({
	component: import('@/components/Commons/Alerts/WInfo')
}))

// Buttons
Vue.component('WButton', () => ({
	component: import('@/components/Commons/Buttons/WBtn')
}))

Vue.component('w-btn', () => ({
	component: import('@/components/Commons/Buttons/WBtn')
}))

Vue.component('w-btn-delete', () => ({
	component: import('@/components/Commons/Buttons/WBtnDelete')
}))

Vue.component('w-btn-edit', () => ({
	component: import('@/components/Commons/Buttons/WBtnEdit')
}))

Vue.component('w-btn-save', () => ({
	component: import('@/components/Commons/Buttons/WBtnSave')
}))

Vue.component('w-btn-view', () => ({
	component: import('@/components/Commons/Buttons/WBtnView')
}))

// Data tables
Vue.component('w-data-table', () => ({
	component: import('@/components/Commons/WDataTable')
}))

Vue.component('w-matrix', () => ({
	component: import('@/components/Commons/WMatrix')
}))

// Dialogs
Vue.component('WDialog', () => ({
	component: import('@/components/Commons/WDialog')
}))

Vue.component('w-dialog', () => ({
	component: import('@/components/Commons/WDialog')
}))

// Flex
Vue.component('w-flex', () => ({
	component: import('@/components/Commons/WFlex')
}))

// Forms
Vue.component('w-form-builder', () => ({
	component: import('@/components/Commons/WFormBuilder')
}))

// Icons
Vue.component('w-icon', () => ({
	component: import('@/components/Commons/WIcon')
}))

// Inputs
Vue.component('w-checkbox', () => ({
	component: import('@/components/Commons/Inputs/WCheckbox')
}))

Vue.component('w-color-picker', () => ({
	component: import('@/components/Commons/Pickers/ColorPicker')
}))

Vue.component('w-currency-input', () => ({
	component: import('@/components/Commons/Inputs/WCurrencyInput')
}))

Vue.component('w-number-input', () => ({
	component: import('@/components/Commons/Inputs/WNumberInput')
}))

Vue.component('w-date-picker', () => ({
	component: import('@/components/Commons/Pickers/DatePicker')
}))

Vue.component('w-date-range-picker', () => ({
	component: import('@/components/Commons/Pickers/WDateRangePicker')
}))

Vue.component('w-duration-input', () => ({
	component: import('@/components/Commons/Inputs/WDurationInput')
}))

Vue.component('w-email-input', () => ({
	component: import('@/components/Commons/Inputs/WEmailInput')
}))

Vue.component('w-multiple-email-input', () => ({
	component: import('@/components/Commons/Inputs/WMultipleEmailInput')
}))

Vue.component('w-image-input', () => ({
	component: import('@/components/Commons/Inputs/ImageInput')
}))

Vue.component('w-password-input', () => ({
	component: import('@/components/Commons/Inputs/WPasswordInput')
}))

Vue.component('w-phone-input', () => ({
	component: import('@/components/Commons/Inputs/WPhoneInput')
}))

Vue.component('w-radio', () => ({
	component: import('@/components/Commons/Inputs/WRadio')
}))

Vue.component('w-resource', () => ({
	component: import('@/components/Commons/Resource/WResource')
}))

Vue.component('w-rich-text-editor', () => ({
	component: import('@/components/Commons/Inputs/WRichTextEditor')
}))

Vue.component('w-search-input', () => ({
	component: import('@/components/Commons/Inputs/WSearchInput')
}))

Vue.component('w-switch', () => ({
	component: import('@/components/Commons/Inputs/WSwitch')
}))

Vue.component('w-text-input', () => ({
	component: import('@/components/Commons/Inputs/WTextInput')
}))

Vue.component('w-time-input', () => ({
	component: import('@/components/Commons/Inputs/WTimeInput')
}))

Vue.component('w-url-input', () => ({
	component: import('@/components/Commons/Inputs/WUrlInput')
}))
Vue.component('w-year-picker', () => ({
	component: import('@/components/Commons/Pickers/WYearPicker')
}))

// Layouts
Vue.component('w-layout', () => ({
	component: import('@/components/Commons/WLayout')
}))

// Lists
Vue.component('w-virtual-list', () => ({
	component: import('@/components/Commons/Lists/WVirtualList')
}))

Vue.component('w-list', () => ({
	component: import('@/components/Commons/Lists/WList')
}))

Vue.component('w-list-tile', () => ({
	component: import('@/components/Commons/Lists/WListTile')
}))

Vue.component('w-list-tile-action', () => ({
	component: import('@/components/Commons/Lists/WListTileAction')
}))

Vue.component('w-list-tile-avatar', () => ({
	component: import('@/components/Commons/Lists/WListTileAvatar')
}))

Vue.component('w-list-tile-content', () => ({
	component: import('@/components/Commons/Lists/WListTileContent')
}))

Vue.component('w-list-tile-title', () => ({
	component: import('@/components/Commons/Lists/WListTileTitle')
}))

Vue.component('w-list-tile-sub-title', () => ({
	component: import('@/components/Commons/Lists/WListTileSubTitle')
}))

// Menu
Vue.component('w-menu', () => ({
	component: import('@/components/Commons/WMenu')
}))

// Navigation Drawer
Vue.component('w-navigation-drawer', () => ({
	component: import('@/components/Commons/WNavigationDrawer')
}))

// Sections
Vue.component('WSection', () => ({
	component: import('@/components/Commons/WSection')
}))

Vue.component('w-section', () => ({
	component: import('@/components/Commons/WSection')
}))

// Selects
Vue.component('w-autocomplete', () => ({
	component: import('@/components/Commons/WAutocomplete')
}))

Vue.component('w-combobox', () => ({
	component: import('@/components/Commons/WCombobox')
}))

Vue.component('w-select', () => ({
	component: import('@/components/Commons/WSelect')
}))

// Tooltips
Vue.component('w-tooltip', () => ({
	component: import('@/components/Commons/WTooltip')
}))

// Others
Vue.component('w-divider', () => ({
	component: import('@/components/Commons/WDivider')
}))

Vue.component('w-form', () => ({
	component: import('@/components/Commons/WForm')
}))

Vue.component('w-pagination', () => ({
	component: import('@/components/Commons/WPagination')
}))

Vue.component('w-spacer', () => ({
	component: import('@/components/Commons/WSpacer')
}))

Vue.component('w-tab', () => ({
	component: import('@/components/Commons/WTab')
}))

Vue.component('w-tabs', () => ({
	component: import('@/components/Commons/WTabs')
}))

Vue.component('w-text-alert', () => ({
	component: import('@/components/Commons/WTextAlert')
}))

Vue.component('w-text-info', () => ({
	component: import('@/components/Commons/WTextInfo')
}))

Vue.component('w-iframe', () => ({
	component: import('@/components/Commons/WIframe')
}))

Vue.component('WModule', () => ({
	component: import('@/components/Commons/Auth/WModule')
}))
